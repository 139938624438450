@tailwind base;
@tailwind components;
@tailwind utilities;

@import "sal.js/dist/sal.css";

body {
  background-color: #fff;
}

.nav-hover-effect {
  @apply relative  block     border-stone-600 bg-transparent   py-2 px-1 text-right  text-base font-light   tracking-[1px]     text-white transition-all duration-300 hover:text-secondary md:border-0 md:pl-3 md:pr-1 md:text-primary;
}
.nav-hover-effect::after {
  @apply absolute left-3 -bottom-[1px] h-[1px] w-[0%] rounded-xl duration-500 content-[''] md:bg-secondary;
}

.nav-hover-effect:hover::after {
  @apply w-[50%] lg:w-[80%];
}
.nav-sub-items {
  @apply block  py-2 px-4 text-sm text-secondary transition-all duration-150 hover:scale-105  hover:text-stone-600  xl:text-sm;
}
.project-sort-list {
  @apply block bg-slate-600 py-2 px-4  text-xs text-gray-200 transition-all duration-150 hover:scale-105 hover:text-stone-600  dark:bg-slate-600     dark:text-gray-600 lg:text-sm;
}

.nav-social {
  @apply font-light text-white duration-500  hover:scale-150 hover:text-secondary md:text-primary;
}
.topbar-item-hover {
  @apply text-primary  duration-500 hover:scale-150 hover:text-secondary;
}

.footer-item-hover {
  @apply mr-6 text-stone-800 decoration-stone-600 duration-200  hover:text-secondary hover:underline;
}

/* Spacing  */
.section--spacing-x {
  @apply mx-5 md:mx-10 lg:mx-20;
}
.section--spacing-y {
  @apply my-10 md:my-20 lg:my-32;
}

/* Typography  */
.heading--one {
  @apply font-poiret text-2xl  uppercase xl:text-3xl;
}

.heading--xxxl {
  @apply font-poiret text-lg uppercase md:text-2xl xl:text-3xl;
}

.project--title {
  @apply font-poiret text-xl uppercase text-secondary  hover:text-primary md:text-2xl;
}
.project--title-btn {
  @apply font-poiret text-xl uppercase text-white md:text-2xl;
}

.sub--title {
  @apply font-oswald text-sm font-light uppercase lg:text-base;
}

.description--styles {
  @apply text-justify font-oswald text-lg font-thin;
}

.hero--text-banner {
  @apply absolute top-[80%] text-left md:top-[86%]  2xl:top-[70%];
}

.hero--text-inner {
  @apply absolute top-[80%] text-left text-white;
}
